import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./static.css";
import Img from "../img/one.jpg";
import Plan from "../img/plane1.JPG";
import Auth from "../img/plane2.JPG";
import Type from "../img/verh.jpg";
import $ from "jquery";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: ""
    };
  }

  changeForm = event => {
    this.setState({ [event.target.attributes.name.value]: event.target.value });
  };

  submit = event => {
    event.preventDefault();
    var data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    };
    $.ajax({
      type: "POST",
      url: "https://annabormatova.com/mail.php",
      data: data
    })
      .done(function (data) {
        $("#name").val("");
        $("#email").val("");
        $("#phone_reg").val("");
        $("#message").val("");
        $("#answer").html(
          '<div class="alert alert-success alert-dismissible fade show mx-auto">Ваше сообщение отправлено!<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span></button></div>'
        );
        $("#answer").show();
      })
      .fail(function (jqXhr) {
        $("#answer").html(
          '<div class="alert alert-danger alert-dismissible fade show mx-auto">Ошибка при отправке<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span></button></div>'
        );
        $("#answer").show();
        console.log("failed to register");
      });
  };

  choose() {
    if (this.props.active === "about") {
      return (
        <div>
          <p>
            Привет, я Анна. С 2015 года создаю и реализую интерьеры. От моего эскиза до вашего переезда.                 
            На моём счету уже более 20 реализованных проектов, каждый из которых уникален.                                   
            Имею 6 наград в сфере дизайна. Закончила МГХПА им. С. Г. Строганова.
            Спроектирую ваше пространство с особым вниманием к деталям и вашим индивидуальным потребностям.
          </p>
          <p>Работаю в Москве, Московской области и удалённо.</p>
          <p>
            Обращайтесь!
          </p>
        </div>
      );
    } else if (!this.props.active) {
      let disabled = true;
      if ((this.state.name, this.state.email, this.state.phone))
        disabled = false;
      return (
        <div className="row">
          <ul
            className={
              window.innerHeight < 720 && window.innerWidth > 960
                ? "col-6"
                : "col-12"
            }
          >
            <li className="static_li">
              <a href="tel:8-916-25-95-174" className="tel">
                &#56;&#45;&#57;&#49;&#54;&#45;&#50;&#53;&#45;&#57;&#53;&#45;&#49;&#55;&#52;
              </a>
            </li>
            <li className="static_li">
              <a href="mailto:&#65;&#110;&#110;&#66;&#111;&#114;&#109;&#97;&#116;&#111;&#118;&#97;&#64;&#121;&#97;&#110;&#100;&#101;&#120;&#46;&#114;&#117;?subject=Письмо с сайта">
                &#65;&#110;&#110;&#66;&#111;&#114;&#109;&#97;&#116;&#111;&#118;&#97;&#64;&#121;&#97;&#110;&#100;&#101;&#120;&#46;&#114;&#117;
              </a>
            </li>
            <li className="static_li">
              <a
                href="https://vk.com/anna.bormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                vk
              </a>
            </li>
            <li className="static_li">
              <a
                href="https://youtube.com/@anna.bormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                youtube
              </a>
            </li>
          </ul>
          <form
            className="col-12 col-lg-6 form"
            name="form"
            onSubmit={this.submit}
          >
            <div className="form-group">
              <input
                type="text"
                value={this.state.name}
                onChange={this.changeForm.bind(this)}
                name="name"
                required
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Имя Фамилия"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                required
                value={this.state.email}
                onChange={this.changeForm.bind(this)}
                name="email"
                className="form-control"
                id="email"
                placeholder="Почта"
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                required
                value={this.state.phone}
                onChange={this.changeForm.bind(this)}
                className="form-control"
                id="phone_reg"
                name="phone"
                placeholder="Телефон"
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control limit_me"
                value={this.state.message}
                onChange={this.changeForm.bind(this)}
                name="message"
                placeholder="Сообщение"
                id="message"
                rows="3"
              />
            </div>
            {disabled ? (
              <button
                type="submit"
                className="btn btn_hoverD"
                aria-disabled="true"
              >
                ОТПРАВИТЬ
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn_hover"
                aria-disabled="true"
              >
                ОТПРАВИТЬ
              </button>
            )}
            <div className="answer">
              <div id="answer" />
            </div>
          </form>
        </div>
      );
    } else if (this.props.active === "planning_solution") {
      return (
        <div>
          - Замер и фотофиксация.
          <br />
          - Разработка нескольких эскизных вариантов планировки (2-4 варианта)
          <br />
          - Готовое планировочного решения объекта в соответствии с техническим
          заданием заказчика (включает расстановку мебели,сантехнического
          оборудования и приборов).
          <br />- План сносимых и возводимых стен и перегородок.
          <br />
          <br />
          Стоимость от 800 р/кв.м
        </div>
      );
    } else if (this.props.active === "authors_supervision") {
      return (
        <div>
          - Выезд на объект (не менее одного раза в неделю).
          <br />
          - Контроль своевременного заказа и поставки отделочных материалов и
          предметов мебели, согласно утверждённому графику работ.
          <br />
          - Выезд в салоны и магазины с заказчиком.
          <br />
          - Консультирование и взаимодействие со смежными организациями.
          <br />
          - Проверка и утверждение коммерческих предложений от поставщиков в
          части соответствия размеров, комплектности и количества изделий.
          <br />
          <br />
          Стоимость от 30 000 р/месяц.
          <br />
          Рассчитывается отдельно и не входит в стоимость проектных работ.
          <br />
          Договор на ведение авторского надзора заключается отдельно.
        </div>
      );
    } else if (this.props.active === "selection_of_furniture") {
      return (
        <div>
          - Выезд на объект для произведения фотофиксации и замера
          <br />
          - Подбор мебели, освещения, декора (разработка элементов под
          индивидуальное изготовление)
          <br />
          - Коллажи по зонам с указанием используемых материалов.
          <br />
          - Ведомости по мебели, освещению, декору, элементам индивидуального
          изготовления
          <br />
          <br />
          Стоимость от 500 р/кв.м
        </div>
      );
    } else if (this.props.active === "typical_projects") {
      return (
        <div>
          - Мини-павильоны от 50 000 р/проект
          <br />
          - Срубы от 100 000 р/проект
          <br />
          - Декор от 30 000 р/проект
          <br />
          <br />
          Раздел находится в разработке. Подробная информация по запросу.
        </div>
      );
    }
  }

  render() {
    let img = Img;
    if (
      this.props.active === "planning_solution" ||
      this.props.active === "selection_of_furniture"
    )
      img = Plan;
    if (this.props.active === "authors_supervision") img = Auth;
    if (this.props.active === "typical_projects") img = Type;

    return (
      <div
        className="col-12 col-lg-8 col-xl-9 for_main_img_s"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="white_contacts">{this.choose()}</div>
      </div>
    );
  }
}

export default Main;

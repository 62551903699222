import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./static.css";
import Img from "../img/one.jpg";
import Plan from "../img/plane1.JPG";
import Auth from "../img/plane2.JPG";
import Type from "../img/verh.jpg";
import $ from "jquery";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: ""
    };
  }

  changeForm = event => {
    this.setState({ [event.target.attributes.name.value]: event.target.value });
  };

  submit = event => {
    event.preventDefault();
    var data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message
    };
    $.ajax({
      type: "POST",
      url: "https://annabormatova.com/mail.php",
      data: data
    })
      .done(function (data) {
        $("#name").val("");
        $("#email").val("");
        $("#phone_reg").val("");
        $("#message").val("");
        $("#answer").html(
          '<div class="alert alert-success alert-dismissible fade show mx-auto">Your message has been sent!<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span></button></div>'
        );
        $("#answer").show();
      })
      .fail(function (jqXhr) {
        $("#answer").html(
          '<div class="alert alert-danger alert-dismissible fade show mx-auto">Error sending<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span></button></div>'
        );
        $("#answer").show();
        console.log("failed to register");
      });
  };

  choose() {
    if (this.props.active === "about") {
      return (
        <div>
          <p>
            Anna Bormatova is architect and designer, graduated in Moscow State
            Academy of industry and applied arts named by S.G.Stroganov. Since
            2015, engaged in her own architectural practice, designing and
            managing the construction of various scales and complexity for both
            private clients and for organizations.
          </p>
        </div>
      );
    } else if (!this.props.active) {
      let disabled = true;
      if ((this.state.name, this.state.email, this.state.phone))
        disabled = false;
      return (
        <div className="row">
          <ul
            className={
              window.innerHeight < 720 && window.innerWidth > 960
                ? "col-6"
                : "col-12"
            }
          >
            <li className="static_li">
              <a href="tel:8-916-25-95-174" className="tel">
                &#56;&#45;&#57;&#49;&#54;&#45;&#50;&#53;&#45;&#57;&#53;&#45;&#49;&#55;&#52;
              </a>
            </li>
            <li className="static_li">
              <a href="mailto:&#65;&#110;&#110;&#66;&#111;&#114;&#109;&#97;&#116;&#111;&#118;&#97;&#64;&#121;&#97;&#110;&#100;&#101;&#120;&#46;&#114;&#117;?subject=Письмо с сайта">
                &#65;&#110;&#110;&#66;&#111;&#114;&#109;&#97;&#116;&#111;&#118;&#97;&#64;&#121;&#97;&#110;&#100;&#101;&#120;&#46;&#114;&#117;
              </a>
            </li>
            <li className="static_li">
              <a
                href="https://www.facebook.com/annbormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                facebook
              </a>
            </li>
            <li className="static_li">
              <a
                href="https://youtube.com/@anna.bormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                youtube
              </a>
            </li>
          </ul>
          <form
            className="col-12 col-lg-6 form"
            name="form"
            onSubmit={this.submit}
          >
            <div className="form-group">
              <input
                type="text"
                value={this.state.name}
                onChange={this.changeForm.bind(this)}
                name="name"
                required
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Full Name"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                required
                value={this.state.email}
                onChange={this.changeForm.bind(this)}
                name="email"
                className="form-control"
                id="email"
                placeholder="E-mail"
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                required
                value={this.state.phone}
                onChange={this.changeForm.bind(this)}
                className="form-control"
                id="phone_reg"
                name="phone"
                placeholder="Phone"
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control limit_me"
                value={this.state.message}
                onChange={this.changeForm.bind(this)}
                name="message"
                placeholder="Message"
                id="message"
                rows="3"
              />
            </div>
            {disabled ? (
              <button
                type="submit"
                className="btn btn_hoverD"
                aria-disabled="true"
              >
                SEND
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn_hover"
                aria-disabled="true"
              >
                SEND
              </button>
            )}
            <div className="answer">
              <div id="answer" />
            </div>
          </form>
        </div>
      );
    } else if (this.props.active === "planning_solution") {
      return (
        <div>
          - Measurments and photos of apartment.
          <br />
          - Designing of few furniture plans (2-4 variations)
          <br />
          - Full furniture plan of apartment according to technical task from
          customer (including setting of sanitary ware).
          <br />
          - Dismantling plan and assembly plan.
          <br />
          <br />
          From $13 per m2
        </div>
      );
    } else if (this.props.active === "authors_supervision") {
      return (
        <div>
          - Control of building process.
          <br />
          - Assistance in purchases and replenishment selection.
          <br />
          <br />
          From $491 per month.
        </div>
      );
    } else if (this.props.active === "selection_of_furniture") {
      return (
        <div>
          - Measurments and photos of apartment.
          <br />
          - Selection of furniture, illumination, decoration (designing of
          elements)
          <br />
          - Collages of rooms with selected materials.
          <br />
          - Specifications of furniture, illumination, decoration and other
          elements.
          <br />
          <br />
          From $10 per m2.
        </div>
      );
    } else if (this.props.active === "typical_projects") {
      return (
        <div>
          - Mini pavilions from $820 per project
          <br />
          - Wood houses from $1640 per project
          <br />
          - Decorative items for outside uses from $491 per project
          <br />
          <br />
          The section is under construction. Detailed information on request.
        </div>
      );
    }
  }

  render() {
    let img = Img;
    if (
      this.props.active === "planning_solution" ||
      this.props.active === "selection_of_furniture"
    )
      img = Plan;
    if (this.props.active === "authors_supervision") img = Auth;
    if (this.props.active === "typical_projects") img = Type;
    return (
      <div
        className="col-12 col-lg-8 col-xl-9 for_main_img_s"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="white_contacts">{this.choose()}</div>
      </div>
    );
  }
}

export default Main;

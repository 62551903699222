import React from "react";
import Menu from "./components/menu";
import MenuM from "./components/menuM";
import Main from "./components/main";
import Small from "./components/small";
import Static from "./components/static";
import MenuMEn from "./components/menuMEn";
import MenuEn from "./components/menuEn";
import MainEn from "./components/mainEn";
import SmallEn from "./components/smallEn";
import StaticEn from "./components/staticEn";
import $ from "jquery";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

let main = [];
let all = [];

var mainPath = () => {
  if (window.innerWidth <= 960)
    return (
      <div className="row main">
        <MenuM />
        <Main main={main} all={all} />
      </div>
    );
  else
    return (
      <div className="row main">
        <Menu />
        <Main main={main} all={all} />
      </div>
    );
};

var watch = event => {
  if (window.innerWidth <= 960)
    return (
      <div className="row main">
        <MenuM active="white" path={event.match.path} />
        <Main path={event.match.path} all={all} />
      </div>
    );
  else
    return (
      <div className="row main">
        <Menu active="white" path={event.match.path} />
        <Main path={event.match.path} all={all} />
      </div>
    );
};

var portfolio = event => {
  if (String(event.match.path).includes("/portfolio/all")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="portfolio" activeSmall="all" />
          <Small path={event.match.path} activeSmall="all" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="portfolio" activeSmall="all" />
          <Small path={event.match.path} activeSmall="all" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/interiors")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="portfolio" activeSmall="interiors" />
          <Small path={event.match.path} activeSmall="interiors" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="portfolio" activeSmall="interiors" />
          <Small path={event.match.path} activeSmall="interiors" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/muf")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="portfolio" activeSmall="muf" />
          <Small path={event.match.path} activeSmall="muf" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="portfolio" activeSmall="muf" />
          <Small path={event.match.path} activeSmall="muf" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/houses")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="portfolio" activeSmall="houses" />
          <Small path={event.match.path} activeSmall="houses" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="portfolio" activeSmall="houses" />
          <Small path={event.match.path} activeSmall="houses" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="portfolio" />
          <Main main={main} all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="portfolio" />
          <Main main={main} all={all} />
        </div>
      );
  }
};

var services = event => {
  if (String(event.match.path) === "/services") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" />
          <Main main={main} all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" />
          <Main main={main} all={all} />
        </div>
      );
  } else if (String(event.match.path) === "/services/full_project") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" activeSmall="full_project" />
          <div
            className="white"
            style={{
              marginTop: "25vh",
              fontFamily: "Open Sans,sans-serif",
              fontWeight: "600"
            }}
          >
            1 этап
            <br />
            - Замер и фотофиксация
            <br />
            - Утверждение бюджета проекта
            <br />
            - Подготовка планировочного решения объекта в соответствии с
            техническим заданием заказчика
            <br />
            - Подбор и согласование отделочных материалов, предметов мебели,
            осветительных приборов и сантехнического
            <br />
            оборудования - Коллажи по помещениям
            <br />
            <br />
            2 этап
            <br />
            - Обмерный план.
            <br />
            - План сносимых и возводимых стен и перегородок
            <br />
            - План расстановки мебели
            <br />
            - План расстановки сантехнических приборов
            <br />
            - План напольных покрытий.
            <br />
            - Разрезы по полу.
            <br />
            - План привязки отопительных приборов и схема укладки тёплого пола.
            <br />
            - План потолков.
            <br />
            - Разрезы по потолкам.
            <br />
            - План привязки осветительных приборов, с указанием оборудования.
            <br />
            - Группы включения осветительных приборов.
            <br />
            - План привязки выключателей.
            <br />
            - План электрических розеток.
            <br />
            - План с указанием отделочных материалов по стенам.
            <br />
            - Развёртки по всем помещениям проекта.
            <br />
            <br />
            3 этап
            <br />
            - 3D-визуализация в соответствии с утверждённой рабочей
            документацией.
            <br />
            - Ведомости отделочных материалов, мебели, света и прочих
            комплектующих
            <br />
            <br />
            Стоимость от 3000 р/кв.м
          </div>
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" activeSmall="full_project" />
          <div
            className="col-12 col-lg-8 col-xl-9 white"
            style={{ fontFamily: "Open Sans,sans-serif", fontWeight: "600" }}
          >
            1 этап
            <br />
            - Замер и фотофиксация
            <br />
            - Утверждение бюджета проекта
            <br />
            - Подготовка планировочного решения объекта в соответствии с
            техническим заданием заказчика
            <br />
            - Подбор и согласование отделочных материалов, предметов мебели,
            осветительных приборов и сантехнического
            <br />
            оборудования - Коллажи по помещениям
            <br />
            <br />
            2 этап
            <br />
            - Обмерный план.
            <br />
            - План сносимых и возводимых стен и перегородок
            <br />
            - План расстановки мебели
            <br />
            - План расстановки сантехнических приборов
            <br />
            - План напольных покрытий.
            <br />
            - Разрезы по полу.
            <br />
            - План привязки отопительных приборов и схема укладки тёплого пола.
            <br />
            - План потолков.
            <br />
            - Разрезы по потолкам.
            <br />
            - План привязки осветительных приборов, с указанием оборудования.
            <br />
            - Группы включения осветительных приборов.
            <br />
            - План привязки выключателей.
            <br />
            - План электрических розеток.
            <br />
            - План с указанием отделочных материалов по стенам.
            <br />
            - Развёртки по всем помещениям проекта.
            <br />
            <br />
            3 этап
            <br />
            - 3D-визуализация в соответствии с утверждённой рабочей
            документацией.
            <br />
            - Ведомости отделочных материалов, мебели, света и прочих
            комплектующих
            <br />
            <br />
            Стоимость от 3000 р/кв.м
          </div>
        </div>
      );
  } else if (String(event.match.path) === "/services/planning_solution") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" activeSmall="planning_solution" />
          <Static active="planning_solution" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" activeSmall="planning_solution" />
          <Static active="planning_solution" all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/services/authors_supervision") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" activeSmall="authors_supervision" />
          <Static active="authors_supervision" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" activeSmall="authors_supervision" />
          <Static active="authors_supervision" all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/services/selection_of_furniture") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" activeSmall="selection_of_furniture" />
          <Static active="selection_of_furniture" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" activeSmall="selection_of_furniture" />
          <Static active="selection_of_furniture" all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/services/typical_projects") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="services" activeSmall="typical_projects" />
          <Static active="typical_projects" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="services" activeSmall="typical_projects" />
          <Static active="typical_projects" all={main} />
        </div>
      );
  }
};

var contacts = event => {
  if (String(event.match.path) === "/contacts") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="contacts" />
          <Static active={null} all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="contacts" />
          <Static active={null} all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/contacts/about") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuM active="contacts" activeSmall="about" />
          <Static active="about" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <Menu active="contacts" activeSmall="about" />
          <Static active="about" all={main} />
        </div>
      );
  }
};

/* ************************************************* */

var mainPathEn = () => {
  if (window.innerWidth <= 960)
    return (
      <div className="row main">
        <MenuMEn />
        <MainEn main={main} all={all} />
      </div>
    );
  else
    return (
      <div className="row main">
        <MenuEn />
        <MainEn main={main} all={all} />
      </div>
    );
};

var watchEn = event => {
  if (window.innerWidth <= 960)
    return (
      <div className="row main">
        <MenuMEn active="white" path={event.match.path} />
        <MainEn path={event.match.path} all={all} />
      </div>
    );
  else
    return (
      <div className="row main">
        <MenuEn active="white" path={event.match.path} />
        <MainEn path={event.match.path} all={all} />
      </div>
    );
};

var portfolioEn = event => {
  if (String(event.match.path).includes("/portfolio/all")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="portfolio" activeSmall="all" />
          <SmallEn path={event.match.path} activeSmall="all" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="portfolio" activeSmall="all" />
          <SmallEn path={event.match.path} activeSmall="all" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/interiors")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="portfolio" activeSmall="interiors" />
          <SmallEn path={event.match.path} activeSmall="interiors" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="portfolio" activeSmall="interiors" />
          <SmallEn path={event.match.path} activeSmall="interiors" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/muf")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="portfolio" activeSmall="muf" />
          <SmallEn path={event.match.path} activeSmall="muf" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="portfolio" activeSmall="muf" />
          <SmallEn path={event.match.path} activeSmall="muf" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio/houses")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="portfolio" activeSmall="houses" />
          <SmallEn path={event.match.path} activeSmall="houses" all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="portfolio" activeSmall="houses" />
          <SmallEn path={event.match.path} activeSmall="houses" all={all} />
        </div>
      );
  } else if (String(event.match.path).includes("/portfolio")) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="portfolio" />
          <MainEn main={main} all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="portfolio" />
          <MainEn main={main} all={all} />
        </div>
      );
  }
};

var servicesEn = event => {
  if (String(event.match.path) === "/services/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" />
          <MainEn main={main} all={all} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" />
          <MainEn main={main} all={all} />
        </div>
      );
  } else if (String(event.match.path) === "/services/full_project/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" activeSmall="full_project" />
          <div
            className="col-12 col-lg-8 col-xl-9 white"
            style={{
              marginTop: "25vh",
              fontFamily: "Open Sans,sans-serif",
              fontWeight: "600"
            }}
          >
            1 step
            <br />
            - Measurments and photos of apartments
            <br />
            - Set the budget of project
            <br />
            - Designing furniture plan according to technical task from customer
            <br />
            - Selection and approval of finishing materials, furniture, lighting
            and sanitary equipment
            <br />
            - Collages of rooms
            <br />
            <br />
            2 step
            <br />
            - The plan of measurements.
            <br />
            - Dismantling plan and assembly plan.
            <br />
            - Furniture plan.
            <br />
            - The plan of sanitary ware.
            <br />
            - The flooring plan.
            <br />
            - Floor cuts.
            <br />
            - The specification of radiators and the heating plan.
            <br />
            - The ceiling plan.
            <br />
            - Ceiling cuts.
            <br />
            - The plan of placement of illumination devices with specifications
            of illumination.
            <br />
            - The plan of illumination and switches group.
            <br />
            - The sockets and electrical leads plan.
            <br />
            - The plan of finishing materials for walls.
            <br />
            - The developed view of the walls of the room.
            <br />
            <br />
            3 step
            <br />
            - 3-D design of an apartment.
            <br />
            - Specifications of finishing materials, furniture, illumination and
            other components
            <br />
            <br />
            From $57 per m2
          </div>
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" activeSmall="full_project" />
          <div
            className="white"
            style={{ fontFamily: "Open Sans,sans-serif", fontWeight: "600" }}
          >
            1 step
            <br />
            - Measurments and photos of apartments
            <br />
            - Set the budget of project
            <br />
            - Designing furniture plan according to technical task from customer
            <br />
            - Selection and approval of finishing materials, furniture, lighting
            and sanitary equipment
            <br />
            - Collages of rooms
            <br />
            <br />
            2 step
            <br />
            - The plan of measurements.
            <br />
            - Dismantling plan and assembly plan.
            <br />
            - Furniture plan.
            <br />
            - The plan of sanitary ware.
            <br />
            - The flooring plan.
            <br />
            - Floor cuts.
            <br />
            - The specification of radiators and the heating plan.
            <br />
            - The ceiling plan.
            <br />
            - Ceiling cuts.
            <br />
            - The plan of placement of illumination devices with specifications
            of illumination.
            <br />
            - The plan of illumination and switches group.
            <br />
            - The sockets and electrical leads plan.
            <br />
            - The plan of finishing materials for walls.
            <br />
            - The developed view of the walls of the room.
            <br />
            <br />
            3 step
            <br />
            - 3-D design of an apartment.
            <br />
            - Specifications of finishing materials, furniture, illumination and
            other components
            <br />
            <br />
            From $57 per m2
          </div>
        </div>
      );
  } else if (String(event.match.path) === "/services/planning_solution/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" activeSmall="planning_solution" />
          <StaticEn active="planning_solution" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" activeSmall="planning_solution" />
          <StaticEn active="planning_solution" all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/services/authors_supervision/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" activeSmall="authors_supervision" />
          <StaticEn active="authors_supervision" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" activeSmall="authors_supervision" />
          <StaticEn active="authors_supervision" all={main} />
        </div>
      );
  } else if (
    String(event.match.path) === "/services/selection_of_furniture/en"
  ) {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" activeSmall="selection_of_furniture" />
          <StaticEn active="selection_of_furniture" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" activeSmall="selection_of_furniture" />
          <StaticEn active="selection_of_furniture" all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/services/typical_projects/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="services" activeSmall="typical_projects" />
          <StaticEn active="typical_projects" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="services" activeSmall="typical_projects" />
          <StaticEn active="typical_projects" all={main} />
        </div>
      );
  }
};

var contactsEn = event => {
  if (String(event.match.path) === "/contacts/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="contacts" />
          <StaticEn active={null} all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="contacts" />
          <StaticEn active={null} all={main} />
        </div>
      );
  } else if (String(event.match.path) === "/contacts/about/en") {
    if (window.innerWidth <= 960)
      return (
        <div className="row main">
          <MenuMEn active="contacts" activeSmall="about" />
          <StaticEn active="about" all={main} />
        </div>
      );
    else
      return (
        <div className="row main">
          <MenuEn active="contacts" activeSmall="about" />
          <StaticEn active="about" all={main} />
        </div>
      );
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      small: window.innerWidth <= 960 ? true : false,
      all: [],
      main: [],
      interiors: [],
      muf: [],
      houses: []
    };
  }

  componentDidMount() {
    $.post("https://annabormatova.com/get.php", data => {
      all = JSON.parse(data);
      let interiors = JSON.parse(data).filter(el => el[0][0] === "interiors");
      let muf = JSON.parse(data).filter(el => el[0][0] === "muf");
      let houses = JSON.parse(data).filter(el => el[0][0] === "houses");
      this.setState({ interiors, muf, houses, all: JSON.parse(data) });
    });
    $.post("https://annabormatova.com/get_main.php", data => {
      main = JSON.parse(data);
      this.setState({ main: JSON.parse(data) });
    });
  }

  getRoute() {
    let routes = [];
    this.state.interiors.forEach(el => {
      let path = "/portfolio/interiors/" + el[1];
      routes.push(
        <Route exact path={path} component={portfolio} />,
        <Route exact path={path + "/watch"} component={watch} />,
        <Route exact path={path + "/en"} component={portfolioEn} />,
        <Route exact path={path + "/watch/en"} component={watchEn} />
      );
    });

    this.state.muf.forEach(el => {
      let path = "/portfolio/muf/" + el[1];
      routes.push(
        <Route exact path={path} component={portfolio} />,
        <Route exact path={path + "/watch"} component={watch} />,
        <Route exact path={path + "/en"} component={portfolioEn} />,
        <Route exact path={path + "/watch/en"} component={watchEn} />
      );
    });

    this.state.houses.forEach(el => {
      let path = "/portfolio/houses/" + el[1];
      routes.push(
        <Route exact path={path} component={portfolio} />,
        <Route exact path={path + "/watch"} component={watch} />,
        <Route exact path={path + "/en"} component={portfolioEn} />,
        <Route exact path={path + "/watch/en"} component={watchEn} />
      );
    });
    return routes;
  }

  render() {
    var changeSmall = value => this.setState({ small: value });
    let value;
    window.onresize = function () {
      if (window.innerHeight > 960) value = false;
      if (window.innerWidth <= 960) value = true;
      changeSmall(value);
    };
    return (
      <div className="container-fluid">
        {this.state.all.length &&
          this.state.main.length &&
          this.state.muf.length &&
          this.state.interiors.length &&
          this.state.houses.length ? (
          <Router>
            <Route exact path="/" component={mainPath} />
            <Route exact path="/portfolio" component={portfolio} />
            <Route exact path="/portfolio/all" component={portfolio} />
            <Route exact path="/portfolio/interiors" component={portfolio} />
            <Route exact path="/portfolio/muf" component={portfolio} />
            <Route exact path="/portfolio/houses" component={portfolio} />
            <Route exact path="/services" component={services} />
            <Route exact path="/services/full_project" component={services} />
            <Route
              exact
              path="/services/planning_solution"
              component={services}
            />
            <Route
              exact
              path="/services/authors_supervision"
              component={services}
            />
            <Route
              exact
              path="/services/selection_of_furniture"
              component={services}
            />
            <Route
              exact
              path="/services/typical_projects"
              component={services}
            />
            <Route exact path="/contacts" component={contacts} />
            <Route exact path="/contacts/about" component={contacts} />
            {/* ************************************** */}
            <Route exact path="/en" component={mainPathEn} />
            <Route exact path="/portfolio/en" component={portfolioEn} />
            <Route exact path="/portfolio/all/en" component={portfolioEn} />
            <Route
              exact
              path="/portfolio/interiors/en"
              component={portfolioEn}
            />
            <Route exact path="/portfolio/muf/en" component={portfolioEn} />
            <Route exact path="/portfolio/houses/en" component={portfolioEn} />
            <Route exact path="/services/en" component={servicesEn} />
            <Route
              exact
              path="/services/full_project/en"
              component={servicesEn}
            />
            <Route
              exact
              path="/services/planning_solution/en"
              component={servicesEn}
            />
            <Route
              exact
              path="/services/authors_supervision/en"
              component={servicesEn}
            />
            <Route
              exact
              path="/services/selection_of_furniture/en"
              component={servicesEn}
            />
            <Route
              exact
              path="/services/typical_projects/en"
              component={servicesEn}
            />
            <Route exact path="/contacts/en" component={contactsEn} />
            <Route exact path="/contacts/about/en" component={contactsEn} />
            {this.getRoute()}
          </Router>
        ) : null}
      </div>
    );
  }
}

export default App;

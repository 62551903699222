import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menu.css";
import { Link } from "react-router-dom";
import youtube from "../img/youtube.png";
import fasebook from "../img/facebook.png";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      text: ""
    };
  }

  renderSmallMenu() {
    if (this.props.active === "portfolio") {
      return (
        <div className="under_items ">
          <Link
            className={
              this.props.activeSmall === "all"
                ? "small_item small_item_main active_small"
                : "small_item small_item_main small_item_hover"
            }
            to="/portfolio/all/en"
          >
            ALL
          </Link>
          <Link
            className={
              this.props.activeSmall === "interiors"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/portfolio/interiors/en"
          >
            INTERIORS
          </Link>
          <Link
            className={
              this.props.activeSmall === "muf"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/portfolio/muf/en"
          >
            SMALL ARCHITECTURAL FORMS
          </Link>
          <Link
            className={
              this.props.activeSmall === "houses"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/portfolio/houses/en"
          >
            HOUSES
          </Link>
        </div>
      );
    } else if (this.props.active === "services") {
      return (
        <div className="under_items ">
          <Link
            className={
              this.props.activeSmall === "full_project"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/services/full_project/en"
          >
            COMPLETE PROJECT
          </Link>
          <Link
            className={
              this.props.activeSmall === "planning_solution"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/services/planning_solution/en"
          >
            FURNITURE PLAN
          </Link>
          <Link
            className={
              this.props.activeSmall === "authors_supervision"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/services/authors_supervision/en"
          >
            DESIGN SUPERVISION
          </Link>
          <Link
            className={
              this.props.activeSmall === "selection_of_furniture"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/services/selection_of_furniture/en"
          >
            SELECTION OF FURNITURE
          </Link>
          <Link
            className={
              this.props.activeSmall === "typical_projects"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/services/typical_projects/en"
          >
            TYPICAL PROJECTS
          </Link>
        </div>
      );
    } else if (this.props.active === "contacts") {
      return (
        <div className="under_items">
          <Link
            className={
              this.props.activeSmall === "about"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/contacts/about/en"
          >
            ABOUT
          </Link>
        </div>
      );
    } else return null;
  }

  changeActive = () => { };

  render() {
    return (
      <div className="col-12 col-lg-4 col-xl-3 root_menu">
        <div className="for_max_width mx-auto">
          <div className="col-11 offset-1 for_name">
            <a
              className="en"
              href={window.location.href.replace('/en', '')
                // .split("/")
                // .slice(0, -1)
                // .join("/")
              }
            >
              RU
            </a>
            <Link className="name" to="/en">
              <span className="name grey">ANNA</span>
              <span className="name">BORMATOVA</span>
              <span className="name grey">ARCHITECTS</span>
            </Link>
            <span className="utp"><nobr>«SMART DESIGN GIVES WINGS»</nobr></span>
          </div>
          <div className="col-11 menu offset-1">
            <hr className="hr" />
            <div className="col p-0 for_hr">
              <Link
                className={
                  this.props.active === "portfolio"
                    ? "item activve"
                    : "item item_hover"
                }
                to="/portfolio/en"
              >
                PORTFOLIO
              </Link>
              <Link
                className={
                  this.props.active === "services"
                    ? "item activve"
                    : "item item_hover"
                }
                to="/services/en"
              >
                SERVICES
              </Link>
              <Link
                className={
                  this.props.active === "contacts"
                    ? "item activve"
                    : "item item_hover"
                }
                to="/contacts/en"
              >
                CONTACTS
              </Link>
            </div>
          </div>
          <div className="col-10 offset-1 pad">{this.renderSmallMenu()}</div>

          <div className="footer">
            <div className="call-block">
              {this.props.active !== "contacts" || this.props.activeSmall === "about" ?
                <Link
                  className="call"
                  to="/contacts/en"
                >
                  <nobr>FEEDBACK FORM</nobr>
                </Link> : null
              }
            </div>
            <div className="footer-block">
              {/* col-10 offset-1  */}
              <a
                href="https://youtube.com/@anna.bormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} className="sety" alt="" />
              </a>
              <a
                href="https://www.facebook.com/annbormatova"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fasebook} className="sety" alt="" />
              </a>
              <a href="tel:8-916-25-95-174" className="tel">
                &#56;&#45;&#57;&#49;&#54;&#45;&#50;&#53;&#45;&#57;&#53;&#45;&#49;&#55;&#52;
              </a>
            </div>
          </div>
          {this.props.active === "white" ? (
            <Link className="name" to={this.props.path.split("/watch")[0]}>
              <div className="white_menu" />
            </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Menu;

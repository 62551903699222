import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menuM.css";
import { Link } from "react-router-dom";
import youtube from "../img/youtube.png";
import vk from "../img/vk.png";

class MenuM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      text: ""
    };
    window.onload = function () {
      this.navbar1b = document.getElementById("navbar1b");
    }
  }

  renderSmallMenu() {
    if (this.props.active === "portfolio") {
      return <div className="under_items " />;
    } else if (this.props.active === "services") {
      return <div className="under_items " />;
    } else if (this.props.active === "contacts") {
      return (
        <div className="under_items">
          <Link
            className={
              this.props.activeSmall === "about"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/contacts/about"
          >
            О СТУДИИ
          </Link>
        </div>
      );
    } else return null;
  }

  changeActive = () => { };

  render() {
    let last = window.location.href[window.location.href.length - 1]
    //console.log(window.location.href, last);
    let en = last === "/" ? "en" : "/en";
    return (
      <div className="col-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div className="col-9  col-sm-10 for_name">
            <a
              className="en"
              href={
                window.location.href.length > 25
                  ? window.location.href.indexOf('watch') < 0 ? window.location.href + en :
                    window.location.href.replace('watch', 'watch/en')
                  : window.location.href + en
              }
            >
              EN
            </a>
            <Link className="name" to="/">
              <span className="name grey anna">ANNA</span>
              <span className="name">BORMATOVA</span>
              <span className="name grey">ARCHITECTS</span>
            </Link>
            {console.log(this.navbar1b)}
            {/* {this.navbar1b?.getAttribute('aria-expanded') !== 'true' ? null : <span className="utp"><nobr>«УМНЫЙ ДИЗАЙН ОКРЫЛЯЕТ»</nobr></span>} */}
            <span className="utp"><nobr>«УМНЫЙ ДИЗАЙН ОКРЫЛЯЕТ»</nobr></span>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            id="navbar1b"
            data-toggle="collapse"
            data-target="#navbar1"
            aria-controls="navbar1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse navbar-m" id="navbar1">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  ПОРТФОЛИО
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "all"
                        ? "small_item small_item_main dropdown-item active_small"
                        : "small_item small_item_main dropdown-item small_item_hover"
                    }
                    to="/portfolio/all"
                  >
                    ВСЕ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "interiors"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/interiors"
                  >
                    ИНТЕРЬЕРЫ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "muf"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/muf"
                  >
                    МАФ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "houses"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/houses"
                  >
                    ДОМА
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  УСЛУГИ
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "full_project"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/full_project"
                  >
                    ПОЛНЫЙ ПРОЕКТ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "planning_solution"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/planning_solution"
                  >
                    ПЛАНИРОВОЧНОЕ РЕШЕНИЕ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "authors_supervision"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/authors_supervision"
                  >
                    АВТОРСКИЙ НАДЗОР
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "selection_of_furniture"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/selection_of_furniture"
                  >
                    ПОДБОР МЕБЕЛИ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "typical_projects"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/typical_projects"
                  >
                    ТИПОВЫЕ ПРОЕКТЫ
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  КОНТАКТЫ
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "about"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/contacts"
                  >
                    КОНТАКТЫ
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "about"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/contacts/about"
                  >
                    О СТУДИИ
                  </Link>
                </div>
              </li>
            </ul>
            <div className="footer">
              <div className="call-block">
                {this.props.active !== "contacts" || this.props.activeSmall === "about" ?
                  <Link
                    className="call"
                    to="/contacts"
                  >
                    <nobr>ЗАПИСАТЬСЯ НА КОНСУЛЬТАЦИЮ</nobr>
                  </Link> : null
                }
              </div>
              <div className="footer-block">
                {/* col p-0 */}
                <a
                  href="https://youtube.com/@anna.bormatova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} className="sety" alt="" />
                </a>
                <a
                  href="https://vk.com/anna.bormatova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={vk} className="sety" alt="" />
                </a>
                <a href="tel:8-916-25-95-174" className="tel">
                  &#56;&#45;&#57;&#49;&#54;&#45;&#50;&#53;&#45;&#57;&#53;&#45;&#49;&#55;&#52;
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default MenuM;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menuM.css";
import { Link } from "react-router-dom";
import youtube from "../img/youtube.png";
import fasebook from "../img/facebook.png";

class MenuM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      text: ""
    };
  }

  renderSmallMenu() {
    if (this.props.active === "portfolio") {
      return <div className="under_items " />;
    } else if (this.props.active === "services") {
      return <div className="under_items " />;
    } else if (this.props.active === "contacts") {
      return (
        <div className="under_items">
          <Link
            className={
              this.props.activeSmall === "about"
                ? "small_item active_small"
                : "small_item small_item_hover"
            }
            to="/contacts/about/en"
          >
            О СТУДИИ
          </Link>
        </div>
      );
    } else return null;
  }

  changeActive = () => { };

  render() {
    return (
      <div className="col-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div className="col-9  col-sm-10 for_name">
            <a
              className="en"
              href={window.location.href.replace('/en', '')
                // .split("/")
                // .slice(0, -1)
                // .join("/")
              }
            >
              RU
            </a>
            <Link className="name" to="/en">
              <span className="name grey anna">ANNA</span>
              <span className="name">BORMATOVA</span>
              <span className="name grey">ARCHITECTS</span>
            </Link>
            <span className="utp"><nobr>«SMART DESIGN GIVES WINGS»</nobr></span>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar1"
            aria-controls="navbar1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse navbar-m" id="navbar1">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  PORTFOLIO
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "all"
                        ? "small_item small_item_main dropdown-item active_small"
                        : "small_item small_item_main dropdown-item small_item_hover"
                    }
                    to="/portfolio/all/en"
                  >
                    ALL
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "interiors"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/interiors/en"
                  >
                    INTERIORS
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "muf"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/muf/en"
                  >
                    SMALL ARCHITECTURAL FORMS
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "houses"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/portfolio/houses/en"
                  >
                    HOUSES
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  SERVICES
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "full_project"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/full_project/en"
                  >
                    COMPLETE PROJECT
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "planning_solution"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/planning_solution/en"
                  >
                    FURNITURE PLAN
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "authors_supervision"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/authors_supervision/en"
                  >
                    DESIGN SUPERVISION
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "selection_of_furniture"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/selection_of_furniture/en"
                  >
                    SELECTION OF FURNITURE
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "typical_projects"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/services/typical_projects/en"
                  >
                    TYPICAL PROJECTS
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  CONTACTS
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown1"
                >
                  <Link
                    className={
                      this.props.activeSmall === "about"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/contacts/en"
                  >
                    CONTACTS
                  </Link>
                  <Link
                    className={
                      this.props.activeSmall === "about"
                        ? "small_item dropdown-item active_small"
                        : "small_item dropdown-item small_item_hover"
                    }
                    to="/contacts/about/en"
                  >
                    ABOUT
                  </Link>
                </div>
              </li>
            </ul>
            <div className="footer">
              <div className="call-block">
                {this.props.active !== "contacts" || this.props.activeSmall === "about" ?
                  <Link
                    className="call"
                    to="/contacts/en"
                  >
                    <nobr>FEEDBACK FORM</nobr>
                  </Link> : null
                }
              </div>
              <div className="footer-block">
                {/* <div className="col p-0" style={{ textAlign: "start" }}> */}
                <a
                  href="https://youtube.com/@anna.bormatova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} className="sety" alt="" />
                </a>
                <a
                  href="https://www.facebook.com/annbormatova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fasebook} className="sety" alt="" />
                </a>
                <a href="tel:8-916-25-95-174" className="tel">
                  &#56;&#45;&#57;&#49;&#54;&#45;&#50;&#53;&#45;&#57;&#53;&#45;&#49;&#55;&#52;
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default MenuM;

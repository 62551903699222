import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/carousel";
import "./small.css";
import $ from "jquery";

class Small extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watch: false,
      opened: null,
      img: null,
      new: true
    };
    this.showProject = false;
    this.numbers = [
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen"
    ];
    this.pathes = [
      "one.",
      "two.",
      "three.",
      "four.",
      "five.",
      "six.",
      "seven.",
      "eight.",
      "nine.",
      "ten.",
      "eleven.",
      "twelve.",
      "thirteen.",
      "fourteen.",
      "fifteen.",
      "sixteen."
    ];
    this.imgsNum = [];
    this.count = -1;
  }

  componentDidMount() {
    let final = this.props.path.substr(this.props.path.lastIndexOf("/") + 1);
    let watch = false;
    let imgsNum = [];
    let allImgs = [];
    let img = null;
    if (this.props.all) {
      if (final === "all") {
        img = this.props.all;
        for (let i = 0; i < img.length; i++) {
          let temp;
          if ((temp = img[i][3].find(el => el.indexOf(this.pathes[0]) + 1))) {
            let adr =
              "https://annabormatova.com/images/" +
              img[i][0] +
              "/" +
              img[i][1] +
              "/" +
              temp;
            let path = "/portfolio/" + img[i][0] + "/" + img[i][1];
            let num = img[i][4][0].substring(0, img[i][4][0].length - 1);
            let id = "#" + num;
            imgsNum.push(num);
            allImgs.push([id, path, adr, img[i][1], img[i][2][0]]);
          }
        }
      } else if (
        final === "interiors" ||
        final === "muf" ||
        final === "houses"
      ) {
        if (window.innerWidth <= 960) {
          let img = this.props.all.filter(el => el[0][0] === final);
          for (let i = 0; i < img.length; i++) {
            let temp;
            if ((temp = img[i][3].find(el => el.indexOf(this.pathes[0]) + 1))) {
              let adr =
                "https://annabormatova.com/images/" +
                final +
                "/" +
                img[i][1] +
                "/" +
                temp;
              let path = "/portfolio/" + final + "/" + img[i][1];
              let num = img[i][6][0].substring(0, img[i][6][0].length - 1);
              let id = "#" + num;
              imgsNum.push(num);
              allImgs.push([
                id,
                path,
                adr,
                img[i][1],
                img[i][2][0],
                img[i][2][1]
              ]);
            }
          }
        } else {
          let img = this.props.all.filter(el => el[0][0] === final);
          for (let i = 0; i < img.length; i++) {
            let temp;
            if ((temp = img[i][3].find(el => el.indexOf(this.pathes[0]) + 1))) {
              let adr =
                "https://annabormatova.com/images/" +
                final +
                "/" +
                img[i][1] +
                "/" +
                temp;
              let path = "/portfolio/" + final + "/" + img[i][1];
              let num = img[i][5][0].substring(0, img[i][5][0].length - 1);
              let id = "#" + num;
              imgsNum.push(num);
              allImgs.push([
                id,
                path,
                adr,
                img[i][1],
                img[i][2][0],
                img[i][2][1]
              ]);
            }
          }
        }
      } else {
        this.showProject = true;
        let img = this.props.all.filter(el => el[1][0] === final);
        let i = 0;
        img[0][3].forEach(el => {
          let adr =
            "https://annabormatova.com/images/" +
            this.props.activeSmall +
            "/" +
            final +
            "/" +
            el;
          let path =
            "/portfolio/" + this.props.activeSmall + "/" + final + "/watch";
          let id = "#" + el.split(".")[0];
          imgsNum.push(el.split(".")[0]);
          allImgs.push([id, path, adr, final, el]);
          watch = true;
          i++;
        });
      }
    }
    let divs = [];
    let k, j;
    let i = 15;
    while (i >= 0) {
      let result = imgsNum.find(el => el.includes(this.numbers[i]));
      if (result) {
        k = i;
        i = 0;
      }
      i--;
    }
    for (j = 0; j <= k; j++) {
      let style = "col-6 col-sm-4 col-lg-3 for_small_img ";
      if (window.innerWidth <= 960) style = style + "mobile";
      divs.push(<div className={style} id={this.numbers[j]} />);
    }
    this.setState({ allImgs, watch });
    this.showImgs(divs);
  }

  async showDivs(divs) {
    this.setState({ divs });
  }

  showImgs = async divs => {
    await this.showDivs(divs);
    for (let j = 0; j < this.state.allImgs.length; j++) {
      if (this.state.watch)
        $(this.state.allImgs[j][0]).html(
          `<a href="` +
          this.state.allImgs[j][1] +
          `?var=` +
          this.state.allImgs[j][4] +
          `"><img src="` +
          this.state.allImgs[j][2] +
          `" class="small_img_color" alt="` +
          this.state.allImgs[j][3] +
          `"/><div class="surprises"></div></a>`
        );
      else {
        if (window.innerWidth <= 960)
          $(this.state.allImgs[j][0]).html(
            // `<a href="#" onClick="$('.surprises').trigger('mouseenter');" class="filter" ><img src="` +
            `<a href="` +
            this.state.allImgs[j][1] +
            `" class="filter" ><img src="` +
            this.state.allImgs[j][2] +
            `" class="small_img" alt="` +
            this.state.allImgs[j][3] +
            `" key="` +
            this.state.allImgs[j][0] +
            `"/><div class="surprises"></div><div class="white_inf inff">` +
            this.state.allImgs[j][3] +
            `<br/>` +
            this.state.allImgs[j][4] +
            `</div></a>`
          );
        else
          $(this.state.allImgs[j][0]).html(
            `<a 
        href="` +
            this.state.allImgs[j][1] +
            `" class="filter" ><img src="` +
            this.state.allImgs[j][2] +
            `" class="small_img" alt="` +
            this.state.allImgs[j][3] +
            `" key="` +
            this.state.allImgs[j][0] +
            `"/><div class="surprises"></div><div class="white_inf inff">` +
            this.state.allImgs[j][3] +
            `<br/>` +
            this.state.allImgs[j][4] +
            `</div></a>`
          );
      }
    }
  };

  render() {
    if ($(":hover")[7]) {
      console.log('opened', this.state.opened)
      if (this.state.opened !== $(":hover")[7].id) {
        this.setState({ opened: $(":hover")[7].id });
        this.count = -1;
      } else {
        this.count++;
      }
    }
    console.log('count', this.count)
    if (this.count >= 1) {
      let res;
      this.state.allImgs.forEach(elem => {
        if (elem[0] === "#" + this.state.opened) res = elem;
      });
      // console.log('res', res)
      document.location.href = res[1];
    }
    let inf = this.props.all.find(
      el =>
        el[1][0] ===
        this.props.path.substr(this.props.path.lastIndexOf("/") + 1)
    );
    let txt;
    if (inf) {
      // console.log(inf)
      txt = [
        <p className="p">{inf[1]}</p>,
        <p className="p">{inf[2][0]}</p>,
        inf[7][0].map(el => <p className="p">{el}</p>),
        <p className="p">{inf[2][3]} <a
          className="href"
          href={inf[2][2]}
          target="_blank"
        > {inf[2][2] ? "ссылке" : null} </a></p>,
        // {inf[2][2]} _self
      ];
    }

    return (
      <div className="col-12 col-lg-8 col-xl-9 for_main_imgs">
        <div className="row row_for_small">{this.state.divs}</div>
        {this.showProject ? (
          <div className="row row_for_inf">
            <div>{txt}</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Small;

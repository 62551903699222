import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/util";
import "bootstrap/js/dist/util";
import "bootstrap/js/src/carousel";
import "./main.css";
import { Link } from "react-router-dom";
import left from "../img/left.jpg";
import right from "../img/right.jpg";
import close from "../img/close1.png";
import $ from "jquery";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      res: []
    };
    this.numbers = [
      "one.",
      "two.",
      "three.",
      "four.",
      "five.",
      "six.",
      "seven.",
      "eight.",
      "nine.",
      "ten."
    ];
    this.names = [1];
    this.name = null;
  }

  componentDidMount() {
    this.getImg();
  }

  getImg() {
    let res = [];
    let all = [],
      final,
      temp,
      path;
    if (this.props.all) {
      if (this.props.path) {
        var param = window.location.href.split("=")[1];
        param = param.split(".")[0];
        final = this.props.path.split("/watch/en")[0];
        final = final.substr(final.lastIndexOf("/") + 1);
        temp = this.props.all.find(el => String(el[1]) === final);
        path =
          "https://annabormatova.com/images/" + temp[0] + "/" + temp[1] + "/";
        all = temp[3];

        temp = all.find(el => el.indexOf(param) >= 0);
        res.push(
          <div className="carousel-item active">
            <img
              src={path + temp}
              className="d-block w-100 main_img_white"
              alt="..."
              data-interval="1000"
            />
            <div className="surprise" />
          </div>
        );
        let i = this.numbers.findIndex(el => el.indexOf(param) + 1) + 1;
        let j = i - 2;
        while (i < all.length) {
          temp = all.find(el => el.indexOf(this.numbers[i]) + 1);
          if (temp) {
            res.push(
              <div className="carousel-item">
                <img
                  src={path + temp}
                  className="d-block w-100 main_img_white"
                  alt="..."
                />
                <div className="surprise" />
              </div>
            );
            i++;
          }
        }
        while (j >= 0) {
          temp = all.find(el => el.indexOf(this.numbers[j]) + 1);
          if (temp) {
            res.push(
              <div className="carousel-item">
                <img
                  src={path + temp}
                  className="d-block w-100 main_img_white"
                  alt="..."
                />
                <div className="surprise" />
              </div>
            );
            j--;
          }
        }
      } else {
        path = "https://annabormatova.com/images/main/";
        all = this.props.main;

        temp = all.find(el => el.indexOf(this.numbers[0]) >= 0);
        let laseFinal = temp.split("_")[0];
        res.push(
          <div className="carousel-item active">
            <img
              src={path + temp}
              className="d-block w-100 main_img"
              alt="..."
              data-interval="1000"
            />
            <div className="surprise" />
          </div>
        );
        let i = 1;
        while (i < all.length) {
          temp = all.find(el => el.indexOf(this.numbers[i]) + 1);
          final = temp.split("_")[0];
          this.names.push(final);
          if (temp) {
            res.push(
              <div className="carousel-item">
                <img
                  src={path + temp}
                  className="d-block w-100 main_img"
                  alt="..."
                />
                <div className="surprise" />
              </div>
            );
            i++;
          }
        }

        this.names.push(laseFinal);
        if (!this.props.path) {
          $("#for_name").html(laseFinal);
          $("#for_name").attr(
            "href",
            "/portfolio/" +
            this.props.all.find(el => el[1][0] === laseFinal)[0] +
            "/" +
            laseFinal + "/en"
          );
        }
      }
    }
    this.setState({ res });
  }

  render() {
    if (!this.props.path) {
      let names = this.names;
      let all = this.props.all;
      $("#carouselExampleControls").on("slide.bs.carousel", function (event) {
        $("#for_name").attr(
          "href",
          "/portfolio/" +
          all.find(
            el => el[1][0] === names[$(event.relatedTarget).index()]
          )[0] + "/" +
          names[$(event.relatedTarget).index()] + "/en"
        );
        $("#for_name").html(names[$(event.relatedTarget).index()]);
      });
    }

    return (
      <div className="col-12 col-lg-8 col-xl-9 for_main_img">
        {this.props.path ? (
          <Link
            className="name"
            to={this.props.path.split("/watch")[0] + "/en"}
          >
            <img src={close} className="close_img" alt="close" />
          </Link>
        ) : null}

        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
          pause="false"
          data-interval="2000"
        >
          <div className="carousel-inner">{this.state.res}</div>
        </div>
        <div className="main_footer">
          <a id="for_name" href="/" className="name_for_img" />
          <a
            className="carousel-control-prev strel"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <img src={left} className="strel_img" alt="prev" />
          </a>
          <a
            className="carousel-control-next strel"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <img src={right} className="strel_img" alt="next" />
          </a>
        </div>
      </div>
    );
  }
}

export default Main;
